:root {
  /* fonts & settings */
  --font-serif: "Spectral", ui-serif, Georgia, Cambria, "Times New Roman",
    Times, serif;
  --font-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  /* https://www.fluid-type-scale.com/calculate?minFontSize=16&minWidth=320&minRatio=1.25&maxFontSize=19&maxWidth=1280&maxRatio=1.333&steps=sm%2Cbase%2Cmd%2Clg%2Cxl%2C2xl%2C3xl%2C4xl%2C5xl&baseStep=base&prefix=font-size&decimals=2&useRems=on&remValue=16&previewFont=Inter&previewText=Almost+before+we+knew+it%2C+we+had+left+the+ground&previewWidth=1280 */
  --font-size-base: 1rem;
  --font-size-sm: clamp(0.8rem, 0.07vw + 0.79rem, 0.84rem);
  --font-size-text: clamp(1.1rem, 0.21vw + 1.1rem, 1.1rem);
  --font-size-md: clamp(1.25rem, 0.42vw + 1.17rem, 1.5rem);
  --font-size-lg: clamp(1.56rem, 0.73vw + 1.42rem, 2rem);
  --font-size-xl: clamp(1.95rem, 1.19vw + 1.72rem, 2.66rem);
  --font-size-2xl: clamp(2.44rem, 1.85vw + 2.07rem, 3.55rem);
  --font-size-3xl: clamp(3.05rem, 2.81vw + 2.49rem, 4.73rem);
  --font-size-4xl: clamp(3.81rem, 4.16vw + 2.98rem, 6.31rem);

  /* colors */
  --color-black: #000;
  --color-white: #fff;
  --color-gray: #999;
  --color-gray-light: #f3f3f3;
  --color-dark: #333;

  --color-green: #2e7d32;
  --color-green-light: #e9f7ea;

  --color-orange: #e4941b;

  /* spacings */
  --default-margin: 1rem;
  --small-margin: 0.55rem;
  --big-margin: 2rem;

  /* borders */
  --border-color: #ddd;
  --border-color-active: var(--color-accent-blue);
  --border-radius: 0.375rem;
  --border-radius-md: 0.85rem;

  --transition-speed: 0.25s;
}
