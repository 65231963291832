.main-nav {
  display: grid;
  grid-template-columns: 100px auto;
  padding: var(--default-margin);
  align-items: center;

  a {
    transition: background var(--transition-speed) ease-in-out;
    color: white;
    padding: var(--small-margin);
    border-radius: var(--border-radius);
  }

  .active a {
    font-weight: bold;
    background: rgba(46, 125, 50, 0.95);
  }

  li:not(.active):hover a {
    background: rgba(46, 125, 50, 0.35);
  }

  .logo {
    width: 40px;
  }

  nav {
    justify-items: center;

    ol {
      position: relative;
      left: -60px;
    }
  }
}

nav {
  &.vertical {
    ol,
    ul {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  ol,
  ul {
    list-style: none;
    display: flex;
    gap: var(--default-margin);

    li {
      margin: 0;
    }
  }

  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .main-nav {
    transition: left var(--transition-speed) ease-in-out;
    position: fixed;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    /* padding: var(--big-margin) 0 !important; */
    display: block;
    text-align: center;
    justify-items: center;
    place-content: center;
    z-index: 10;

    background: rgba(46, 125, 50, 0.95) !important;

    &.visible {
      left: 0;
    }

    nav {
      margin-top: var(--default-margin);

      ol {
        display: block;
        left: 0;
        margin: 0;
        padding: 0;
      }

      li {
        display: block;
      }

      a {
        font-size: var(--font-size-lg);
        line-height: 2;
      }
    }
  }
}

.page-links {
  @media screen and (max-width: 767px) {
    .entry {
      margin: var(--big-margin) 0;
      gap: var(--small-margin) !important;
    }
  }
}
