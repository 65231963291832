.separator {
  display: flex;
  justify-content: space-around;
  margin: var(--big-margin) 0;
  svg {
    width: 200px;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    position: relative;
    top: 40px;
    background-image: linear-gradient(to right, lightgray 50%, transparent 50%);
    background-size: 5px 1px; /* Adjust the dash length (10px) and thickness (4px) */
  }
}

.announcement {
  background-color: orange;
  padding: var(--default-margin) 0;
  text-align: center;

  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;

  h2,
  p {
    display: inline-block;
    font-size: var(--font-size-text);
    color: var(--color-black);
  }

  h2 {
    font-weight: bold;
  }
}
