.img-text-wrapper {
  padding: calc(var(--big-margin) * 4) 0;

  &:nth-of-type(even) {
    background-color: #f9f9f9;

    .img-text {
      grid-template-areas: "text images";

      .images {
        justify-self: end;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .img-text-wrapper {
    &:nth-of-type(even) {
      .img-text {
        grid-template-areas: "images text";

        .images {
          justify-self: start;
        }
      }
    }
  }
}

.img-text {
  display: block;
  grid-template-columns: 1fr;
  grid-template-areas: "images" "text";
  gap: var(--default-margin);

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "images text";
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "images text";
  }

  .images {
    grid-area: images;
    position: relative;
    margin-bottom: var(--default-margin);

    img {
      width: 100%;
      position: relative;
      border-radius: var(--border-radius);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      &:nth-child(2) {
        display: none;
      }

      @media screen and (min-width: 768px) {
        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(2) {
          width: 100%;
          display: block;
          margin-top: var(--default-margin);
        }
      }

      @media screen and (min-width: 1024px) {
        &:nth-child(1) {
          width: 80%;
        }

        &:nth-child(2) {
          width: 60%;
          transform: translateX(60%) translateY(-60%);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.21);
        }
      }
    }
  }

  .info {
    grid-area: text;

    h2,
    h3,
    h4,
    p,
    ol,
    ul,
    img {
      margin-bottom: calc(var(--default-margin) * 2);
    }

    h3,
    h4 {
      font-size: var(--font-size-lg);
    }

    img {
      width: 100%;
      border-radius: var(--border-radius);
    }
  }
}
