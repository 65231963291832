.card {
  width: 100%;
  align-self: stretch;
  transition: all var(--transition-speed);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }

  header {
    aspect-ratio: 4/3;
    overflow: hidden;

    img {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
      width: 100%;
      object-fit: cover;
    }
  }

  .body {
    padding: var(--default-margin);

    h4 {
      font-size: var(--font-size-md);
      font-weight: 600;
      margin-bottom: 0;
    }

    .function {
      margin-bottom: var(--default-margin);
      color: var(--color-dark);
    }

    .infos {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: var(--small-margin);
        align-content: center;
        align-items: center;
      }
    }

    .description {
      font-size: var(--font-size-text);
    }
  }
}

.faq {
  transition: all var(--transition-speed) ease-in-out;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: var(--default-margin);
  align-items: center;
  text-align: center;

  background-color: var(--color-gray-light);
  padding: var(--big-margin) var(--default-margin);
  border-radius: var(--border-radius);
  width: 100%;

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
    transform: translateY(-2px);
  }

  header {
    display: grid;
    color: var(--color-green);
    text-align: center;
    justify-items: center;
    word-break: break-all;

    h2 {
      margin: 10px 0 0 0;
      font-size: var(--font-size-md);
    }
  }

  svg {
    position: relative;
    width: 40px;
  }

  .body {
    font-size: var(--font-size-text);
    text-align: left;
  }
}
