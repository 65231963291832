@view-transition {
  navigation: auto;
}

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--font-sans);
  font-size: var(--font-size-base);
  font-size-adjust: from-font;
  min-height: 100vh;
  overflow-x: hidden;
}

* {
  --webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-sans);
  font-weight: normal;
  line-height: 1.2;
}

h1 {
  font-size: var(--font-size-3xl);
}

h2 {
  font-size: var(--font-size-xl);
}

h3 {
  font-size: var(--font-size-xl);
}

h4 {
  font-size: var(--font-size-lg);
}

h5 {
  font-size: var(--font-size-md);
}

h6 {
  font-size: var(--font-size-md);
}

p,
ul,
ol {
  font-size: var(--font-size-text);
}

li {
  margin-bottom: var(--small-margin);
}

strong {
  font-weight: 600;
  color: black;
}

a {
  transition: color var(--transition-speed) ease-in-out;
  color: var(--link-color);

  &:hover {
    color: var(--link-color-hover);
  }
}

article {
  margin: var(--big-margin) 0;
}

.readable-text-length {
  max-width: 65ch;
  margin-left: auto;
  margin-right: auto;
}

.intro {
  p {
    font-size: var(--font-size-md);
    color: var(--color-dark);
    margin-bottom: var(--big-margin);
  }
}

.colums {
  @media screen and (min-width: 768px) {
    columns: 2;
    column-gap: calc(var(--big-margin) * 2);
    text-align: justify;
  }

  p {
    margin-bottom: calc(var(--default-margin) * 2);
  }
}
