.area {
  padding: calc(var(--big-margin) * 8) 0;

  &.small-padding {
    padding: calc(var(--big-margin) * 4) 0;
  }

  header {
    text-align: center;

    h2 {
      font-weight: 700;
      margin: 0;
    }

    .subtitle {
      margin: var(--default-margin) 0;
    }
  }

  .entry {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: var(--default-margin);

    svg {
      width: 80px;
      color: var(--color-green);
    }
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-bottom: var(--default-margin);
  }

  .subtitle {
    color: var(--color-dark);
    font-family: var(--font-serif);
    font-style: italic;
  }

  &.dark {
    background-color: #efefef;
  }

  .cta-wrapper {
    width: 50%;
    background: #efefef;
    display: flex;
    gap: var(--default-margin);
    justify-items: center;
    justify-content: center;
    margin: calc(var(--big-margin) * 4) auto;
    margin-bottom: 0;
    text-align: center;
    border: 1px solid var(--color-gray);
    border-radius: var(--border-radius);
    padding: var(--default-margin);
    padding-bottom: calc(var(--big-margin) * 0.65);

    small {
      display: block;
      margin-bottom: var(--small-margin);
    }
  }

  &.dark {
    .cta-wrapper {
      background: var(--color-white);
    }
  }

  @media screen and (max-width: 767px) {
    padding: calc(var(--big-margin) * 2) 0;

    &.small-padding {
      padding: var(--big-margin) 0;
    }

    .cta-wrapper {
      width: 100%;
      display: block;

      > div {
        margin-bottom: var(--big-margin);
      }

      small {
        display: block;
        margin-bottom: var(--small-margin);
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .cta-wrapper {
      width: 80%;
    }
  }
}
