input,
textarea {
  transition: all var(--transition-speed);
  display: block;
  width: 100%;
  padding: var(--small-margin);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--bg-color);

  margin: var(--small-margin) 0 var(--default-margin) 0;

  &:focus {
    border-color: var(--primary-color);
  }
}

.uniform__potty {
  margin-left: -200vw;
}

.uniform-field-error {
  border-color: #ff3939;
  background-color: #feefef;
}

.message {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: var(--small-margin);
  margin: var(--big-margin) 0;

  &.error {
    border-color: #ff3939;
    background-color: #ffc7c7;
  }

  &.success {
    border-color: var(--color-green);
    background-color: var(--color-green-light);
  }
}

.button {
  transition: all var(--transition-speed);
  display: inline-block;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: var(--small-margin) var(--default-margin);
  background-color: var(--color-white);
  color: var(--color-black);
  text-decoration: none;

  &:hover {
    background: var(--color-gray-light);
    color: var(--color-dark);
  }

  &.primary {
    --border-color: #5a875d;
    color: var(--color-white);
    background-color: var(--color-green);

    &:hover {
      background: #5a875d;
    }
  }

  &.cta {
    font-size: var(--font-size-text);
  }
}
