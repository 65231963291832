.readable-text-length {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: calc(var(--big-margin) * 2) 0 var(--big-margin) 0;
  }
  p,
  ul,
  ol {
    margin-bottom: var(--default-margin);
    line-height: 1.7;
  }
}
