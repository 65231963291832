header.page {
  display: grid;
  position: relative;
  background: green no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;

  .main-nav {
    z-index: 2;
    width: 100%;
    padding-bottom: var(--big-margin);
    background-color: rgba(255, 255, 255, 0);
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.75) 30%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .container {
    position: relative;
    height: 100%;
    color: white;

    padding: calc(var(--big-margin) * 2) 0;

    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .headlines {
    margin: var(--big-margin) 0;
    text-align: center;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .logo {
    margin: 0 auto;
  }

  h2 {
    font-style: italic;
  }

  .buttons {
    display: flex;
    gap: var(--big-margin);

    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media screen and (max-width: 767px) {
      display: block;

      .button {
        display: block;
        margin: var(--small-margin);
        text-align: center;
      }
    }
  }

  .burger {
    position: relative;
    justify-self: end;
    margin: var(--default-margin);
    padding: var(--small-margin);
    border-radius: var(--border-radius);
    z-index: 30;
    color: var(--color-white);
    backdrop-filter: blur(10px);

    svg {
      width: 40px;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
