.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--default-margin);
}

.add-margin {
  margin-top: var(--big-margin);
  margin-bottom: var(--big-margin);
}

.add-margin-md {
  margin-top: calc(var(--big-margin) * 2);
  margin-bottom: calc(var(--big-margin) * 2);
}

.add-margin-lg {
  margin-top: calc(var(--big-margin) * 4);
  margin-bottom: calc(var(--big-margin) * 4);
}

.grid-item {
  --span: 2;
  display: flex;
}

@media screen and (min-width: 320px) {
  .grid-item {
    --span: 12;
  }
}

@media screen and (min-width: 420px) {
  .grid-item {
    --span: 6;
  }
}

@media screen and (min-width: 1024px) {
  .grid-item {
    --span: 4;
  }
}

.auto-grid {
  --grid-gap: var(--default-margin);
  --span: span 12;
  --offset: unset;

  display: grid;
  gap: var(--grid-gap);
  grid-template-columns: repeat(12, 1fr);

  &.vertical-gap {
    margin-bottom: 1.5rem;
  }

  .padding {
    padding: var(--grid-gap);
  }

  > * {
    --offset: auto;
    grid-column: var(--offset, auto) / span var(--span, 12);
  }

  > .span-12 {
    --span: 12;
  }

  > .span-11 {
    --span: 11;
  }

  > .span-10 {
    --span: 10;
  }

  > .span-9 {
    --span: 9;
  }

  > .span-8 {
    --span: 8;
  }

  > .span-7 {
    --span: 7;
  }

  > .span-6 {
    --span: 6;
  }

  > .span-5 {
    --span: 5;
  }

  > .span-4 {
    --span: 4;
  }

  > .span-3 {
    --span: 3;
  }

  > .span-2 {
    --span: 2;
  }

  > .span-1 {
    --span: 1;
  }

  > .offset-12 {
    --offset: 12;
  }

  > .offset-11 {
    --offset: 11;
  }

  > .offset-10 {
    --offset: 10;
  }

  > .offset-9 {
    --offset: 9;
  }

  > .offset-8 {
    --offset: 8;
  }

  > .offset-7 {
    --offset: 7;
  }

  > .offset-6 {
    --offset: 6;
  }

  > .offset-5 {
    --offset: 5;
  }

  > .offset-4 {
    --offset: 4;
  }

  > .offset-3 {
    --offset: 3;
  }

  > .offset-2 {
    --offset: 2;
  }

  > .offset-1 {
    --offset: 1;
  }

  .full-height {
    align-items: stretch;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;

    > * {
      width: 100%;
    }

    > .offset-12,
    > .offset-11,
    > .offset-10,
    > .offset-9,
    > .offset-8,
    > .offset-7,
    > .offset-6,
    > .offset-5,
    > .offset-4,
    > .offset-3,
    > .offset-2,
    > .offset-1 {
      --offset: 1;
    }

    > .span-12,
    > .span-11,
    > .span-10,
    > .span-9,
    > .span-8,
    > .span-7,
    > .span-6,
    > .span-5,
    > .span-4,
    > .span-3,
    > .span-2,
    > .span-1 {
      --span: 2;
    }

    .split-entry {
      --span: 1;
    }
  }
}
