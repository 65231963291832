table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  font-size: var(--font-size-text);
  border-radius: var(--border-radius);

  td,
  th {
    padding: var(--small-margin);
  }

  thead {
    tr {
      th {
        text-align: left;
        font-weight: bold;
        background: var(--color-green);
        color: var(--color-white);
      }
    }
  }

  tbody {
    tr {
      transition: all var(--transition-speed);
      &:nth-of-type(even) {
        background: var(--color-gray-light);
      }

      &:hover {
        background: var(--color-green-light);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  table thead {
    display: none;
    visibility: hidden;
    height: 0;
    position: absolute;
  }

  table tr {
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border: 1px solid;
    border-bottom: none;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 1px solid;
  }
}
