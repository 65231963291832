footer.page {
  position: relative;
  background-color: var(--color-dark);
  color: white;
  padding: var(--big-margin);


  .scroll_to_top {
    transition: all var(--transition-speed);
    position: absolute;
    bottom: var(--big-margin);
    right: var(--big-margin);
    background: var(--color-green);
    border-radius: var(--border-radius);
    padding: var(--default-margin);

    svg {
      width: 30px;
      color: white;
    }

    &:hover {
      filter: brightness(110%);
    }
  }
}
